import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Tabs, Form, Input, InputNumber,
  Switch, Select, Button, Radio,
} from 'antd';
import styled from 'styled-components';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import request from '../../../../logic/utils/request';
import ColorPicker from './components/ColorPicker/ColorPicker';
import randomId from '../../../../logic/utils/randomId';

const { Option } = Select;
const { TextArea } = Input;

const GridContainer = styled.div`
  .ant-radio-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    max-width: 450px;
    margin-bottom: 16px;
  }

  .ant-radio-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 8px;
    text-align: center;
    margin-right: 0 !important;
    
    &:hover {
      border-color: #40a9ff;
    }
    
    &.ant-radio-wrapper-checked {
      border-color: #1890ff;
      background-color: #e6f7ff;
    }

    .ant-radio {
      display: none;
    }
  }
`;

function QualityTabletForm(props) {
  const {
    params, surveyList, fetchTableData, record, videoList, imageList,
  } = props;

  const [state, setState] = useState({});
  useEffect(() => {
    setState({
      ...params,
      logoPositionType: params.logoPositionType || 'grid',
    });
  }, [params]);

  const saveParams = async () => {
    if (record) await request(`/server/api/quality_control/${record.hardware_id}`, 'update', { config: { ...state } });
    else await request('/server/api/officeparams/', 'update', [{ param: 'qualityControlConfig', value: state }]);
    toast.success('Выполнено');
    fetchTableData();
  };

  const changeColor = (value, name) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getVideoName = () => {
    let videoName = 'Не выбрано';
    const { videoApi } = state;
    if (videoList) {
      videoList.forEach((el) => {
        if (el.id === videoApi) videoName = el.name;
      });
    }
    return videoName;
  };

  return (
    <div>

      <Tabs tabPosition="left">
        <Tabs.TabPane tab="Оценки" key="mark">
          <Form>
            <Form.Item label="Внешний вид оценок">
              <Select
                style={{ maxWidth: '450px' }}
                size="large"
                value={state.contentType}
                onChange={(value) => {
                  changeColor(value, 'contentType');
                }}
              >
                <Option value="smiles">Смайлики</Option>
                <Option value="stars">Звездочки</Option>
                <Option value="cards">Карточки</Option>
              </Select>

            </Form.Item>
            <Form.Item label="Текст после оценок">
              <TextArea
                style={{ maxWxidth: '450px' }}
                value={state.afterMarksText}
                onChange={(e) => {
                  changeColor(e.target.value, 'afterMarksText');
                }}
              />
            </Form.Item>
            <Form.Item label="Заголовок оценок">
              <TextArea
                style={{ maxWxidth: '450px' }}
                value={state.headerTitle}
                onChange={(e) => {
                  changeColor(e.target.value, 'headerTitle');
                }}
              />
            </Form.Item>
            <Form.Item label="Цвет заголовка оценок">
              <ColorPicker color={state.marksTitleColor} onChange={changeColor} name="marksTitleColor" />
            </Form.Item>
            <Form.Item label="Оценки от лучшей до худшей">
              <Switch
                size="large"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={state.marksReversed}
                onChange={(e) => {
                  changeColor(e, 'marksReversed');
                }}
              />
            </Form.Item>
            <Form.Item label="Показывать текст под оценками">
              <Switch
                size="large"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={state.showMarkText}
                onChange={(e) => {
                  changeColor(e, 'showMarkText');
                }}
              />
            </Form.Item>
            <Form.Item label="Оценить по долгому нажатию">
              <Switch
                size="large"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={state.markLongPress}
                onChange={(e) => {
                  changeColor(e, 'markLongPress');
                }}
              />
            </Form.Item>
            <Form.Item label="Заголовок оценок">
              <Input
                style={{ maxWxidth: '450px' }}
                size="large"
                value={state.marksTitle}
                onChange={(e) => {
                  changeColor(e.target.value, 'marksTitle');
                }}
              />
            </Form.Item>
            <Form.Item label="Доп. текст над оценками">
              <TextArea
                style={{ maxWxidth: '450px' }}
                value={state.marksAdditional}
                onChange={(e) => {
                  changeColor(e.target.value, 'marksAdditional');
                }}
              />
            </Form.Item>
            <Form.Item label="Размер заголовка оценок">
              <InputNumber
                size="large"
                min={0}
                value={state.marksTitleSize}
                onChange={(e) => {
                  changeColor(e, 'marksTitleSize');
                }}
              />
            </Form.Item>
            <Form.Item label="Размер картинки оценки">
              <InputNumber
                size="large"
                min={0}
                value={state.markImageSize}
                onChange={(e) => {
                  changeColor(e, 'markImageSize');
                }}
              />
            </Form.Item>
            <Form.Item label="Цвет картинки оценки">
              <ColorPicker color={state.markTextColor} onChange={changeColor} name="markTextColor" />
            </Form.Item>
            <Form.Item label="Размер текста оценки">
              <InputNumber
                size="large"
                min={0}
                value={state.markTextSize}
                onChange={(e) => {
                  changeColor(e, 'markTextSize');
                }}
              />
            </Form.Item>
            <Form.Item label="Кол-во оценок">
              <InputNumber
                size="large"
                min={0}
                value={state.marksCount}
                onChange={(e) => {
                  changeColor(e, 'marksCount');
                }}
              />
            </Form.Item>
            <Form.Item label="Текст оценки 1">
              <Input
                style={{ maxWxidth: '450px' }}
                size="large"
                value={state.mark1Text}
                onChange={(e) => {
                  changeColor(e.target.value, 'mark1Text');
                }}
              />
            </Form.Item>
            <Form.Item label="Текст оценки 2">
              <Input
                style={{ maxWxidth: '450px' }}
                size="large"
                value={state.mark2Text}
                onChange={(e) => {
                  changeColor(e.target.value, 'mark2Text');
                }}
              />
            </Form.Item>
            <Form.Item label="Текст оценки 3">
              <Input
                style={{ maxWxidth: '450px' }}
                size="large"
                value={state.mark3Text}
                onChange={(e) => {
                  changeColor(e.target.value, 'mark3Text');
                }}
              />
            </Form.Item>
            <Form.Item label="Текст оценки 4">
              <Input
                style={{ maxWxidth: '450px' }}
                size="large"
                value={state.mark4Text}
                onChange={(e) => {
                  changeColor(e.target.value, 'mark4Text');
                }}
              />
            </Form.Item>
            <Form.Item label="Текст оценки 5">
              <Input
                style={{ maxWxidth: '450px' }}
                size="large"
                value={state.mark5Text}
                onChange={(e) => {
                  changeColor(e.target.value, 'mark5Text');
                }}
              />
            </Form.Item>
            <Form.Item label="Цвет картинки оценки 1">
              <ColorPicker color={state.mark1ImgColor} onChange={changeColor} name="mark1ImgColor" />
            </Form.Item>
            <Form.Item label="Цвет картинки оценки 2">
              <ColorPicker color={state.mark2ImgColor} onChange={changeColor} name="mark2ImgColor" />
            </Form.Item>
            <Form.Item label="Цвет картинки оценки 3">
              <ColorPicker color={state.mark3ImgColor} onChange={changeColor} name="mark3ImgColor" />
            </Form.Item>
            <Form.Item label="Цвет картинки оценки 4">
              <ColorPicker color={state.mark4ImgColor} onChange={changeColor} name="mark4ImgColor" />
            </Form.Item>
            <Form.Item label="Цвет картинки оценки 5">
              <ColorPicker color={state.mark5ImgColor} onChange={changeColor} name="mark5ImgColor" />
            </Form.Item>
          </Form>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Опросы" key="survey">
          <Form>
            <Form.Item label="Только опрос без оценки">
              <Switch
                size="large"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={state.isOnlyQuiz}
                onChange={(e) => {
                  changeColor(e, 'isOnlyQuiz');
                }}
              />
            </Form.Item>
            <Form.Item label="Установить опрос перед оценками">
              <Switch
                size="large"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={state.surveyBeforeMarks}
                onChange={(e) => {
                  changeColor(e, 'surveyBeforeMarks');
                }}
              />
            </Form.Item>

            <Form.Item label="Название опроса, загруженного из админки">
              <Select
                style={{ maxWidth: '450px' }}
                size="large"
                value={state.surveyId}
                onChange={(value) => {
                  changeColor(value, 'surveyId');
                }}
              >
                <Option value="null" key={randomId()}>Не выбрано</Option>
                {surveyList.map((el) => <Option value={el.id} key={el.id}>{el.surveyname}</Option>)}
              </Select>
            </Form.Item>

            <Form.Item label="Включить опрос">
              <Switch
                size="large"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={state.surveyIncluded}
                onChange={(e) => {
                  changeColor(e, 'surveyIncluded');
                }}
              />
            </Form.Item>
            <Form.Item label="Модальное окно перед опросом">
              <Switch
                size="large"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={state.modalBeforeSurvey}
                onChange={(e) => {
                  changeColor(e, 'modalBeforeSurvey');
                }}
              />
            </Form.Item>
          </Form>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Карточки" key="card">
          <Form>
            <Form.Item label="Цвет фона карточки 1">
              <ColorPicker color={state.card1BgColor} onChange={changeColor} name="card1BgColor" />
            </Form.Item>
            <Form.Item label="Цвет фона карточки 2">
              <ColorPicker color={state.card2BgColor} onChange={changeColor} name="card2BgColor" />
            </Form.Item>
            <Form.Item label="Цвет фона карточки 3">
              <ColorPicker color={state.card3BgColor} onChange={changeColor} name="card3BgColor" />
            </Form.Item>
            <Form.Item label="Цвет фона карточки 4">
              <ColorPicker color={state.card4BgColor} onChange={changeColor} name="card4BgColor" />
            </Form.Item>
            <Form.Item label="Цвет фона карточки 5">
              <ColorPicker color={state.card5BgColor} onChange={changeColor} name="card5BgColor" />
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Логотип" key="logo">
          <Form.Item label="Способ позиционирования">
            <Select
              style={{ maxWidth: '450px' }}
              size="large"
              defaultValue="grid"
              value={state.logoPositionType || 'grid'}
              onChange={(value) => {
                changeColor(value, 'logoPositionType');
              }}
            >
              <Option value="grid">Сетка 3x3</Option>
              <Option value="custom">Произвольные координаты</Option>
            </Select>
          </Form.Item>

          {state.logoPositionType === 'grid' ? (
            <Form.Item label="Позиция в сетке">
              <GridContainer>
                <Radio.Group
                  value={state.logoGridPosition || 'top-left'}
                  onChange={(e) => {
                    changeColor(e.target.value, 'logoGridPosition');
                  }}
                >
                  <Radio value="top-left">
                    Верхний
                    <br />
                    левый
                  </Radio>
                  <Radio value="top-center">
                    Верхний
                    <br />
                    центр
                  </Radio>
                  <Radio value="top-right">
                    Верхний
                    <br />
                    правый
                  </Radio>
                  <Radio value="middle-left">
                    Центр
                    <br />
                    слева
                  </Radio>
                  <Radio value="middle-center">Центр</Radio>
                  <Radio value="middle-right">
                    Центр
                    <br />
                    справа
                  </Radio>
                  <Radio value="bottom-left">
                    Нижний
                    <br />
                    левый
                  </Radio>
                  <Radio value="bottom-center">
                    Нижний
                    <br />
                    центр
                  </Radio>
                  <Radio value="bottom-right">
                    Нижний
                    <br />
                    правый
                  </Radio>
                </Radio.Group>
              </GridContainer>
            </Form.Item>
          ) : (
            <>
              <Form.Item label="Позиция X">
                <InputNumber
                  style={{ maxWidth: '200px' }}
                  size="large"
                  min={0}
                  max={100}
                  value={state.logoCustomX}
                  onChange={(value) => {
                    changeColor(value, 'logoCustomX');
                  }}
                />
              </Form.Item>
              <Form.Item label="Позиция Y">
                <InputNumber
                  style={{ maxWidth: '200px' }}
                  size="large"
                  min={0}
                  max={100}
                  value={state.logoCustomY}
                  onChange={(value) => {
                    changeColor(value, 'logoCustomY');
                  }}
                />
              </Form.Item>
            </>
          )}

          <Form.Item label="Размер логотипа">
            <InputNumber
              size="large"
              min={0}
              value={state.customerLogoSize}
              onChange={(e) => {
                changeColor(e, 'customerLogoSize');
              }}
            />
          </Form.Item>
          {state.logoPositionType === 'custom' && (
            <Form.Item label="Отступ от краев экрана">
              <InputNumber
                size="large"
                min={0}
                value={state.logoMargin || 0}
                onChange={(e) => {
                  changeColor(e, 'logoMargin');
                }}
              />
            </Form.Item>
          )}
          <Form.Item label="Название изображения, загруженного из админки">
            <Select
              style={{ maxWidth: '450px' }}
              size="large"
              value={state.logoApi}
              onChange={(value) => {
                changeColor(value, 'logoApi');
              }}
            >
              <Option value="null" key={randomId()}>Не выбрано</Option>
              {imageList.map((el) => <Option value={el.id} key={el.id}>{el.name}</Option>)}
            </Select>
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Видео" key="video">
          <Form.Item label="Показывать видео">
            <Switch
              size="large"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={state.showVideo}
              onChange={(e) => {
                changeColor(e, 'showVideo');
              }}
            />
          </Form.Item>
          <Form.Item label="Выключить звук">
            <Switch
              size="large"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={state.videoMuted}
              onChange={(e) => {
                changeColor(e, 'videoMuted');
              }}
            />
          </Form.Item>
          <Form.Item label="Название видео">
            <Select
              style={{ maxWidth: '450px' }}
              size="large"
              value={state.videoList}
              defaultValue={() => getVideoName()}
              onChange={(value) => {
                changeColor(value, 'videoApi');
              }}
            >
              <Option value="null" key={randomId()}>Не выбрано</Option>
              {videoList.map((el) => <Option value={el.id} key={el.id}>{el.name}</Option>)}
            </Select>
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Другое" key="others">
          <Form.Item label="Показывать телефонный номер отделения">
            <Switch
              size="large"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={state.showOfficePhone}
              onChange={(e) => {
                changeColor(e, 'showOfficePhone');
              }}
            />
          </Form.Item>
          <Form.Item label="Показывать инфо об операторе">
            <Switch
              size="large"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={state.showOperatorInfo}
              onChange={(e) => {
                changeColor(e, 'showOperatorInfo');
              }}
            />
          </Form.Item>
          <Form.Item label="Инфо об операторе по центру">
            <Switch
              size="large"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={state.centerOperatorInfo}
              onChange={(e) => {
                changeColor(e, 'centerOperatorInfo');
              }}
            />
          </Form.Item>
          <Form.Item label="Изображение внизу">
            <Select
              style={{ maxWidth: '450px' }}
              size="large"
              value={state.footerImgApi}
              onChange={(value) => {
                changeColor(value, 'footerImgApi');
              }}
            >
              <Option value="null" key={randomId()}>Не выбрано</Option>
              {imageList.map((el) => <Option value={el.id} key={el.id}>{el.name}</Option>)}
            </Select>
          </Form.Item>
          <Form.Item label="Цвет текста во время ожидания">
            <ColorPicker color={state.idleTextColor} onChange={changeColor} name="idleTextColor" />
          </Form.Item>
          <Form.Item label="Текст во время ожидания">
            <TextArea
              style={{ maxWxidth: '450px' }}
              value={state.idleText}
              onChange={(e) => {
                changeColor(e.target.value, 'idleText');
              }}
            />
          </Form.Item>
          <Form.Item label="Размер текста во время ожидания">
            <InputNumber
              size="large"
              min={0}
              value={state.idleTextSize}
              onChange={(e) => {
                changeColor(e, 'idleTextSize');
              }}
            />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Параметры QR" key="qr">
          <Form.Item label="Текст заголовка QR кода">
            <Input
              style={{ maxWxidth: '450px' }}
              size="large"
              value={state.qrCodeScreenText}
              onChange={(e) => {
                setState({ ...state, qrCodeScreenText: e.target.value });
              }}
            />
          </Form.Item>
          <Form.Item label="Цвет текста заголовка QR кода">
            <ColorPicker color={state.idleTextColor} onChange={changeColor} name="qrCodeScreenTextColor" />
          </Form.Item>
          <Form.Item label="Текст в QR коде">
            <Input
              style={{ maxWxidth: '450px' }}
              size="large"
              value={state.qrCodeScreenData}
              onChange={(e) => {
                setState({ ...state, qrCodeScreenData: e.target.value });
              }}
            />
          </Form.Item>
          <Form.Item label="Отображение QR кода">
            <Switch
              size="large"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              checked={state.isQrCodeScreen}
              onChange={(e) => {
                changeColor(e, 'isQrCodeScreen');
              }}
            />
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>

      <Button type="primary" onClick={saveParams} size="large" style={{ margin: '20px 0 0 108px' }}>
        {record ? 'Применить' : 'Сохранить'}
      </Button>

    </div>
  );
}

export default QualityTabletForm;
