import React from 'react';
import { MdExitToApp, MdHelp } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { Menu, PageHeader } from 'antd';
import { toast } from 'react-toastify';
import NotificationSettings from './NotificationSettings';
import request from '../../../logic/utils/request';
import cleanLocalStorage from '../../../logic/utils/cleanLocalStorage';

function Header(props) {
  const {
    showNotifications,
    setShowNotifications,
    notificationInterval,
    setNotificationInterval,
  } = props;
  const officeName = localStorage.getItem('officeName');
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await request('/server/api/logout/');
      cleanLocalStorage();
      navigate('/login');
    } catch (e) {
      toast.warn('Ошибка');
    }
  };

  const fio = window.localStorage.getItem('fio');

  return (
    <PageHeader
      title={officeName}
      extra={(
        <div style={{ minWidth: 450 }}>
          <Menu mode="horizontal">
            <Menu.Item>
              <NotificationSettings
                showNotifications={showNotifications}
                setShowNotifications={setShowNotifications}
                notificationInterval={notificationInterval}
                setNotificationInterval={setNotificationInterval}
              />
            </Menu.Item>
            <Menu.Item
              style={{ fontSize: 16 }}
              icon={<Icon component={MdHelp} style={{ fontSize: 16 }} />}
            >
              <Link to="/about/">
                <span>О системе</span>
              </Link>
            </Menu.Item>

            <Menu.Item style={{ fontSize: 16 }} onClick={handleLogout}>
              <span>{fio}</span>
              <Icon style={{ marginLeft: '10px', fontSize: 16 }} component={MdExitToApp} />
            </Menu.Item>
          </Menu>

        </div>
      )}
    />
  );
}
export default React.memo(Header);
