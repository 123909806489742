import React from 'react';
import { Type } from 'react-bootstrap-table2-editor';
import SelectInput from '../../../../../../../pages/private/components/Input/SelectInput';
import iconFormatter from '../../../../../../../pages/private/utils/iconFormatter';

export default function Setting({
  screen, queue, screenList,
}) {
  const screens = {
    dataField: 'path',
    text: 'Действие при нажатии',
    sort: true,
    editable: true,
    editorRenderer: (editorProps, value) => {
      const options = [];
      screenList.forEach((el) => {
        if (el.screenId === screen.screenId) return;
        options.push({ name: el.title, id: el.screenId });
      });
      options.push(
        {
          name: 'Сканирование',
          id: 'scan',
        },
        {
          name: 'На печать',
          id: 'print',
        },
        {
          name: 'Предварительная запись',
          id: 'preEntry',
        },
        {
          name: 'Ввод пин-кода',
          id: 'pinCode',
        },
        {
          name: 'Запись ПЗ',
          id: 'preRecord',
        },
      );
      return (
        <SelectInput
          value={value}
          noDefault
          onChange={(e) => editorProps.onUpdate(e.target.value)}
          options={options}
        />
      );
    },
    formatter(cell) {
      let name = 'Не выбрано';
      switch (cell) {
        case 'scan':
          name = 'Сканирование';
          break;
        case 'preRecord':
          name = 'Предварительная запись';
          break;
        case 'pinCode':
          name = 'Ввод пин-кода';
          break;
        case 'print':
          name = 'На печать';
          break;
        case screen.screenId:
          name = 'Не выбрано';
          break;
        default:
          if (screenList.length && cell) {
            screenList.forEach((item) => {
              if (item.screenId === cell) {
                name = item.headTitle || item.title;
              }
            });
          }
          break;
      }
      return name;
    },
  };

  const extraText = {
    dataField: 'extraText',
    text: 'Дополнительный текст',
    editable: true,
    editorRenderer: (editorProps, value, row) => (
      <textarea
        onBlur={(e) => {
          if (!value) row.extraText = '';
          editorProps.onUpdate(e.target.value);
        }}
        defaultValue={value}
      />
    ),
  };

  return {
    columns: [
      {
        dataField: 'name',
        text: 'Текст кнопки',
        editable: true,
        editor: {
          type: Type.TEXTAREA,
        },
      },
      extraText,
      screens,
      {
        dataField: 'params',
        text: 'Очередь',
        sort: true,
        editable: true,
        formatExtraData: {},
        headerStyle: {
          width: 200,
        },
        editorRenderer: (editorProps, value) => {
          const options = [];
          queue.map((el) => options.push({ id: el.id, name: el.fullname }));
          return (
            <SelectInput
              value={value.value}
              maxContent
              noDefaultg
              onChange={(e) => editorProps.onUpdate({
                ...editorProps.defaultValue,
                value: e.target.value,
              })}
              options={options}
            />
          );
        },
        formatter(cell) {
          let name = 'Не выбрано';
          if (queue.length && cell) {
            queue.forEach((item) => {
              if (+item.id === Number(cell.value)) {
                name = item.fullname;
              }
            });
          }
          return name;
        },
      },
      {
        dataField: 'size',
        text: 'Размер',
        sort: false,
        style: {
          width: 175,
        },
        editable: true,
        editorRenderer: (editorProps, value) => {
          const options = [
            { id: 'lg', name: 'Большая' },
            { id: 'md', name: 'Средняя' },
            { id: 'sm', name: 'Маленькая' },
          ];
          return (
            <SelectInput
              value={value}
              noDefault
              onChange={(e) => editorProps.onUpdate(e.target.value)}
              options={options}
            />
          );
        },
        formatter(cell) {
          let name = '';
          switch (cell) {
            case 'lg':
              name = 'Большая';
              break;
            case 'md':
              name = 'Средняя';
              break;
            case 'sm':
              name = 'Маленькая';
              break;

            default:
              break;
          }
          return name;
        },
      },
      {
        dataField: 'position',
        text: 'Позиция',
        isDummyField: true,
        editable: true,
        editorRenderer: (editorProps, value, row) => {
          const options = [];
          let ind = '';
          screen.buttons.forEach((el, index) => {
            if (el.id === row.id) ind = index;
            options.push({ id: index + 1, name: index + 1 });
          });
          return (
            <SelectInput
              value={value}
              width={50}
              noDefault
              onChange={(e) => {
                const el = screen.buttons.splice(ind, 1)[0];
                screen.buttons.splice(e.target.value - 1, 0, el);
                screen.buttons.forEach((btn, index) => {
                  btn.position = index + 1;
                });
                editorProps.onUpdate(e.target.value);
              }}
              options={options}
            />
          );
        },
        formatter(cell, column) {
          return column.position;
        },
      },
      {
        dataField: 'Settings',
        text: '',
        headerStyle: {
          width: 50,
        },
        isDummyField: true,
        formatter: () => iconFormatter({ update: false, write: true }),
        editable: false,
      },
    ],
  };
}
