import React from 'react';
import moment from 'moment';
import { ReactComponent as Trash } from '../../../../../common/images/bin.svg';

export default (users, write, searchPropsFactory, handleTableIcon) => [
  {
    dataIndex: 'user_id',
    title: 'Пользователь',
    ...searchPropsFactory('user_id', 'Пользователь'),
    render: (cell) => (users.some((el) => el.id === cell) ? users.find((el) => el.id === cell).username : ''),
  },
  {
    dataIndex: 'session',
    title: 'Сессия',
    ...searchPropsFactory('session', 'Сессия'),
  },
  {
    dataIndex: 'constant',
    title: 'Тип',
    ...searchPropsFactory('constant', 'Тип'),
    render: (cell) => (cell ? 'Постоянная' : 'Временная'),
  },
  {
    dataIndex: 'created_on',
    title: 'Дата создания',
    ...searchPropsFactory('created_on', 'Дата создания'),
    render: (cell) => moment(cell, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    dataIndex: 'Settings',
    title: '',
    width: 50,
    render: (_, row) => (
      <span
        className="anticon"
        onClick={(e) => handleTableIcon(e, row)}
        style={{ cursor: 'pointer' }}
      >
        <Trash style={{ width: '20px', height: '20px' }} />
      </span>
    ),
  },
];
