import React, { useState } from 'react';
import { Table } from 'antd';
import Config from './config';
import DeleteWarning from '../../../../../common/ui/Modal/DeleteWarning';
import DeleteItem from '../../../../../pages/private/utils/deleteItem';
import { useSearchPropsFactory } from '../../../../../pages/private/utils/useSearch';

function TableSession({
  data, users, write, fetchData, preloader, showError,
}) {
  const [id, setId] = useState([]);
  const [name, setName] = useState(false);
  const [open, setOpen] = useState(false);

  const deleteItem = async () => {
    await DeleteItem({
      preloader, url: `/server/api/usersession/${id}/`, fetchData, setShowWarn: setOpen, setId, showError,
    });
  };

  const handleTableIcon = (e, row) => {
    const {
      user_id: userId, id: rowId,
    } = row;
    setId(rowId);
    setName(users.find((el) => el.id === userId).username);
    setOpen(true);
  };

  const columns = Config(users, write, useSearchPropsFactory(), handleTableIcon);
  return (
    <>
      {users.length > 0 ? (
        <Table
          keys="id"
          dataSource={data}
          columns={columns}
          cellEdit
        />
      ) : null}
      <DeleteWarning
        name={name}
        confirmDel={deleteItem}
        isOpen={open}
        toggleOpen={() => setOpen(false)}
      />
    </>
  );
}

export default TableSession;
