import React from 'react';
import {
  Popover, Button, Switch, InputNumber, Space, Typography,
} from 'antd';
import { BellOutlined, BellFilled } from '@ant-design/icons';

const { Text } = Typography;

function NotificationSettings({
  showNotifications,
  setShowNotifications,
  notificationInterval,
  setNotificationInterval,
}) {
  const handleIntervalChange = (value) => {
    const interval = value || 10; // Если значение пустое или некорректное, используем 10 секунд
    setNotificationInterval(interval);
    localStorage.setItem('notificationInterval', interval.toString());
  };

  const handleNotificationsChange = (checked) => {
    setShowNotifications(checked);
    localStorage.setItem('showNotifications', checked.toString());
  };

  const content = (
    <Space direction="vertical" size="middle" style={{ padding: '12px', minWidth: '250px' }}>
      <Space size="middle">
        <Text style={{ fontSize: '14px' }}>Включить уведомления</Text>
        <Switch
          checked={showNotifications}
          onChange={handleNotificationsChange}
          size="default"
        />
      </Space>
      <Space size="middle">
        <Text style={{ fontSize: '14px' }}>Интервал проверки (сек)</Text>
        <InputNumber
          min={1}
          value={notificationInterval}
          onChange={handleIntervalChange}
          size="default"
        />
      </Space>
    </Space>
  );

  return (
    <Popover
      content={content}
      title="Настройки уведомлений"
      trigger="click"
      placement="bottomRight"
    >
      <Button
        type="text"
        icon={
          showNotifications ? (
            <BellFilled style={{ fontSize: '20px', color: '#52c41a' }} />
          ) : (
            <BellOutlined style={{ fontSize: '20px' }} />
          )
        }
      />
    </Popover>
  );
}

export default NotificationSettings;
