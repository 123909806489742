// import React from 'react';
// import { ReactComponent as Edit } from '../../../../../../../../common/images/edit.svg';
// import c from '../../../../../../../../common/ui/theme/Colors';
import sortCaret from '../../../utils/sortCaret';

// function priceFormatter(column, colIndex, { filterElement, sortElement }, sort) {
//   return (
//     <div className={`filterBoxTable ${sort ? 'sort' : null}`}>
//       {column.dataField === '3' || column.dataField === '4' || column.dataField === '6' ? null : (<Edit style={{ margin: '2px 10px 0 0' }} fill={c.main} />) }
//       { filterElement || column.text }
//       { sortElement }
//     </div>
//   );
// }

// const headerFormatter = (
//   column,
//   colIndex,
//   {
//     filterElement,
//     sortElement,
//   },
// ) => priceFormatter(
//   column,
//   colIndex,
//   { filterElement, sortElement },
//   true,
// );

export default function TableConfig() {
  return {
    columns: [
      {
        dataField: 'questiontext',
        text: 'Вопрос',
        sort: false,
      },
      {
        dataField: 'type',
        text: 'Тип вопроса',
        sort: false,
        formatter: (cell) => (cell === '2' ? 'Цифровой' : 'Текстовый'),
        headerStyle: () => ({ width: '200px' }),
      },
      {
        dataField: 'answertxt',
        text: 'Ответ',
        sortCaret,
        sort: true,
        headerStyle: () => ({ width: '100px' }),
      },
      {
        dataField: 'weight',
        text: 'Числовой эквивалент',
        headerStyle: () => ({ width: '200px' }),
      },
    ],
  };
}
