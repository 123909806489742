import React from 'react';
import { Type } from 'react-bootstrap-table2-editor';
import { Select } from 'antd';
import { BtnTable } from '../../../../../../common/ui/Common';
import { ReactComponent as Trash } from '../../../../../../common/images/del.svg';
import SelectInput from '../../../../../../pages/private/components/Input/SelectInput';
import { icon, maskInputScreen, typeInputScreenButton } from './tableConfigParamsInputScreen';

function iconFormatter() {
  return (
    <div>
      <BtnTable data-name="del" bg="accent">
        <Trash fill="white" />
      </BtnTable>
    </div>
  );
}

export default function Setting({
  screen,
  screenList,
  queue,
  dictOptions,
  fieldsList,
}) {
  const { type } = screen;

  const inputsScrreensTypes = ['inputScreen', 'addressScreen', 'periodScreen'];

  const getButtonType = () => {
    if (inputsScrreensTypes.includes(type) && fieldsList.length > 0) {
      return { ...typeInputScreenButton({ fieldsList }) };
    }
    return {};
  };

  const typeButton = getButtonType();

  const maskInput = type === 'inputScreen' ? { ...maskInputScreen() } : {};
  const iconScreen = type !== 'inputScreen' ? { ...icon } : {};

  const extraText = type !== 'inputScreen' ? {
    dataField: 'extraText',
    text: 'Дополнительный текст',
    sort: true,
    editable: true,
    editorRenderer: (editorProps, value, row) => (
      <textarea
        onBlur={(e) => {
          if (!value) row.extraText = '';
          editorProps.onUpdate(e.target.value);
        }}
        defaultValue={value}
      />
    ),
  } : {};

  const extraTextEng = type !== 'inputScreen' ? {
    dataField: 'extraText_eng',
    text: 'Перевод дополнителього текста',
    sort: true,
    editable: true,
    editorRenderer: (editorProps, value, row) => (
      <textarea
        onBlur={(e) => {
          if (!value) row.extraText_eng = '';
          editorProps.onUpdate(e.target.value);
        }}
        defaultValue={value}
      />
    ),
  } : {};

  let queueDictionary;
  const screenData = screenList.map((item) => ({
    label: item.headTitle || item.title,
    value: item.screenId,
  }));

  let screens = {
    dataField: 'path',
    text: 'Действие при нажатии',
    sort: true,
    editable: true,
    editorRenderer: (editorProps, value) => {
      const options = [];
      screenData.forEach((el) => {
        if (el.value !== screen.screenId) {
          options.push({ id: el.value, name: el.label });
        }
      });
      return (
        <SelectInput
          value={value}
          noDefault
          onChange={(e) => editorProps.onUpdate(e.target.value)}
          options={options}
        />
      );
    },
    formatter(cell) {
      let name = 'Не выбрано';
      switch (cell) {
        case 'scan':
          name = 'Сканирование';
          break;
        case 'preRecord':
          name = 'Запись ПЗ';
          break;
        case 'pinCode':
          name = 'Ввод пин-кода';
          break;
        case 'print':
          name = 'На печать';
          break;
        case 'scanPolicy':
          name = 'Скан полиса';
          break;
        case 'emulatePreRecord':
          name = 'Эмуляция предварительной записи';
          break;
        case 'newPreEntry':
          name = 'Предварительная запись с универсальными слотами';
          break;
        case 'preEntry':
          name = 'Предварительная запись';
          break;
        case screen.screenId:
          name = 'Не выбрано';
          break;
        default:
          if (screenList.length && cell) {
            screenList.forEach((item) => {
              if (item.screenId === cell) {
                name = item.headTitle || item.title;
              }
            });
          }
          break;
      }
      return name;
    },
  };

  if (type === 'inputScreen' || type === 'multiScreen') {
    screens = {};
  }
  if (type === 'dictScreen' || type === 'multiScreen') {
    let dictData = [];
    if (dictOptions.length > 0) {
      dictData = dictOptions.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    }
    queueDictionary = {
      dataField: 'params',
      text: 'Значение доп. поля',
      sort: false,
      headerStyle: {
        width: 200,
      },

      editorRenderer: (editorProps, value) => {
        const options = dictData.map((el) => ({ id: el.value, name: el.label }));
        return (
          <Select
            value={value}
            onChange={(currentValue) => editorProps.onUpdate(Number(currentValue))}
            options={options}
          >
            {options.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        );
      },
      formatter(cell) {
        let name = 'Не выбран';
        if (dictData.length) {
          dictData.forEach((item) => {
            if (item.value === Number(cell.value)) {
              name = item.label;
            }
          });
        }
        return name;
      },
    };
  } else {
    const queueData = queue.map((item) => ({
      label: item.fullname,
      value: item.id,
    }));
    queueDictionary = {
      dataField: 'params',
      text: 'Услуга',
      sort: true,
      editable: true,
      formatExtraData: {},
      headerStyle: {
        width: 200,
      },
      editorRenderer: (editorProps, value) => {
        const options = [{ id: '', name: 'Не выбрано' }];
        queueData.map((el) => options.push({ id: el.value, name: el.label }));
        return (
          <Select
            value={value.value || ''}
            style={{ width: '100%' }}
            onChange={(selectedValue) => editorProps.onUpdate(Number(selectedValue) || '')}
            showSearch
            filterOption={(input, option) => {
              if (!option || !option.children) return false;
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            placeholder="Выберите услугу"
            defaultValue=""
          >
            <Select.Option key="" value="">Не выбрано</Select.Option>
            {options.filter((option) => option.id !== '').map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        );
      },
      formatter(cell) {
        let name = 'Не выбрано';
        if (queue.length && cell) {
          queue.forEach((item) => {
            if (item.id === Number(cell.value)) {
              name = item.fullname;
            }
          });
        }
        return name;
      },
    };
  }

  screenData.unshift({
    label: 'Не выбрано',
    value: '',
  });
  screenData.push(
    {
      label: 'Сканирование',
      value: 'scan',
    },
    {
      label: 'На печать',
      value: 'print',
    },
    {
      label: 'Ввод пин-кода',
      value: 'pinCode',
    },
    {
      label: 'Скан полиса',
      value: 'scanPolicy',
    },
    {
      label: 'Запись ПЗ',
      value: 'preRecord',
    },
    {
      label: 'Предварительная запись по конкретным услугам',
      value: 'preEntry',
    },
    {
      label: 'Предварительная запись с универсальными слотами',
      value: 'newPreEntry',
    },
    {
      label: 'Эмуляция предварительной записи',
      value: 'emulatePreRecord',
    },
  );

  return {
    columns: [
      {
        dataField: 'name',
        text: 'Текст кнопки',
        sort: true,
        editable: true,
        editor: {
          type: Type.TEXTAREA,
        },
      },
      extraText,
      {
        dataField: 'name_eng',
        text: 'Перевод текста кнопки',
        sort: true,
        editable: true,
        editor: {
          type: Type.TEXTAREA,
        },
      },
      extraTextEng,
      screens,
      {
        dataField: 'size',
        text: 'Размер',
        sort: false,
        style: {
          width: 175,
        },
        editable: true,
        editorRenderer: (editorProps, value) => {
          const options = [
            { id: 'lg', name: 'Большая' },
            { id: 'md', name: 'Средняя' },
            { id: 'sm', name: 'Маленькая' },
          ];
          return (
            <SelectInput
              value={value}
              noDefault
              onChange={(e) => editorProps.onUpdate(e.target.value)}
              options={options}
            />
          );
        },
        formatter(cell) {
          let name = '';
          switch (cell) {
            case 'lg':
              name = 'Большая';
              break;
            case 'md':
              name = 'Средняя';
              break;
            case 'sm':
              name = 'Маленькая';
              break;

            default:
              break;
          }
          return name;
        },
      },
      queueDictionary,
      typeButton,
      maskInput,
      iconScreen,
      {
        dataField: 'position',
        text: 'Позиция',
        isDummyField: true,
        editable: true,
        editorRenderer: (editorProps, value, row) => {
          const options = [];
          let ind = '';
          screen.buttons.forEach((el, index) => {
            if (el.id === row.id) ind = index;
            options.push({ id: index + 1, name: index + 1 });
          });
          return (
            <SelectInput
              value={value}
              width={50}
              noDefault
              onChange={(e) => {
                const el = screen.buttons.splice(ind, 1)[0];
                screen.buttons.splice(e.target.value - 1, 0, el);
                screen.buttons.forEach((btn, index) => {
                  btn.position = index + 1;
                });
                editorProps.onUpdate(e.target.value);
              }}
              options={options}
            />
          );
        },
        formatter(cell, column) {
          return column.position;
        },
      },
      {
        dataField: 'Settings',
        text: '',
        headerStyle: {
          width: 50,
        },
        isDummyField: true,
        formatter: iconFormatter,
        formatExtraData: {},
        editable: false,
      },
    ],
  };
}
