import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

function EditorComponent({
  data, height, setData, minHeight,
}) {
  return (
    <Editor
      value={data}
      init={{
        menubar: false,
        branding: false,
        height,
        min_height: minHeight,
        language: 'ru',
        plugins: [
          'lists',
          'table',
          'code',
        ],
        toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | code',
      }}
      onEditorChange={(content) => {
        setData(content);
      }}
    />
  );
}

export default EditorComponent;
