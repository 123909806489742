import React, { useState, useCallback, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';

import SqlQuery from './SqlQuery';
import SqlQueryCloseCnt from './SqlQueryCloseCnt';
import SqlQueryNoneCnt from './SqlQueryNoneCnt';
import BasicLineReportSettings from './BasicLineReportSettings';
import ReportTitle from '../../../ReportTitle';
import { ReportBox } from '../../../../../../../../components/report/ReportTableColumns';
import Preloader from '../../../../../../../../components/preloader/Preloader';
import { PreloaderBox } from '../../../../../../../../../../common/ui/Common';
import request from '../../../../../../../../../../logic/utils/request';

Chart.register(...registerables);

function ReportLine(props) {
  const {
    view, loader, setLoader, endDate, startDate,
  } = props;
  const [ReportParams, setReportParams] = useState();

  const answerFnc = useCallback(async () => {
    setLoader(true);
    const createValues = await SqlQuery(startDate, endDate);
    const closeValues = await SqlQueryCloseCnt(startDate, endDate);
    const noneValues = await SqlQueryNoneCnt(startDate, endDate);
    const BasicSettings = BasicLineReportSettings();
    const officeParams = await request('/server/api/officeparams/', 'get', {});

    const startHour = parseInt(officeParams.data.data.find((param) => (param.param === 'officeStartTime')).value, 10);
    const closingTimeValue = officeParams.data.data.find((param) => (param.param === 'officeClosingTime')).value;

    // Проверяем, содержит ли значение двоеточие (формат HH:MM)
    let endHour;
    if (closingTimeValue.includes(':')) {
      const [hours, minutes] = closingTimeValue.split(':').map((part) => parseInt(part, 10));
      // Если есть минуты, округляем в большую сторону
      endHour = minutes > 0 ? hours + 1 : hours;
    } else {
      // Если формат только часы
      endHour = parseInt(closingTimeValue, 10);
    }
    console.log('endHour', endHour);

    // Фильтруем данные по времени работы
    if (closeValues.data) {
      closeValues.data.splice(0, startHour);
      closeValues.data.splice(endHour - startHour);
    }
    if (noneValues.data) {
      noneValues.data.splice(0, startHour);
      noneValues.data.splice(endHour - startHour);
    }

    const dataGraph = {};
    const dataClose = {};
    const dataNone = {};

    for (let i = 0; i < 24; i += 1) {
      dataGraph[i] = 0;
      dataClose[i] = 0;
      dataNone[i] = 0;
    }

    createValues.forEach((element) => {
      dataGraph[Number(element[1])] = Number(element[0]);
    });

    closeValues.forEach((element) => {
      dataClose[Number(element[1])] = Number(element[0]);
    });

    noneValues.forEach((element) => {
      dataNone[Number(element[1])] = Number(element[0]);
    });

    const allCntData = [];
    for (let i = 0; i < 24; i += 1) {
      allCntData.push(Number(Object.values(dataNone)[i]) + Number(Object.values(dataClose)[i]));
    }

    const getData = (data) => {
      const filteredData = [...data];
      filteredData.splice(0, startHour);
      filteredData.splice(endHour - startHour);
      return filteredData;
    };

    const getLabels = () => {
      const labels = [];
      for (let i = startHour; i < endHour; i++) {
        if (i === 0) {
          labels.push('00:00-01:00');
        } else {
          labels.push(`${String(i).padStart(2, '0')}:00-${String(i + 1).padStart(2, '0')}:00`);
        }
      }
      return labels;
    };

    const allData = {
      ...BasicSettings,
      label: 'Всего обслужено',
      data: getData(Object.values(dataNone).map((value, index) => Number(value) + Number(Object.values(dataClose)[index]))),
      pointHoverBackgroundColor: '#7486fc',
      pointBorderColor: '#7486fc',
      borderColor: '#7486fc',
      backgroundColor: 'rgba(3, 82, 252, 0.4)',
    };

    const noneData = {
      ...BasicSettings,
      label: 'Закрыто по неявке',
      data: getData(Object.values(dataNone)),
      pointHoverBackgroundColor: '#f75454',
      pointBorderColor: '#f75454',
      borderColor: '#f75454',
      backgroundColor: 'rgba(252, 5, 5, 0.4)',
    };

    const createData = {
      ...BasicSettings,
      label: 'Создано',
      data: getData(Object.values(dataGraph)),
      pointHoverBackgroundColor: '#019421',
      pointBorderColor: '#019421',
      borderColor: '#019421',
      backgroundColor: 'rgba(2, 179, 40, 0.4)',
    };

    const closeData = {
      ...BasicSettings,
      label: 'Закрытых',
      data: getData(Object.values(dataClose)),
      pointHoverBackgroundColor: '#20a8d8',
      pointBorderColor: '#20a8d8',
      borderColor: '#20a8d8',
      backgroundColor: 'rgba(75, 192, 192, 0.4)',
    };

    const ReportSettings = {
      labels: getLabels(),
      datasets: [createData, allData, closeData, noneData],
    };
    setReportParams(ReportSettings);
    setLoader(null);
  }, [endDate, startDate]);

  useEffect(() => {
    if (view) {
      answerFnc();
    }
  }, [answerFnc, endDate, startDate, view]);

  return (
    <ReportBox>
      <ReportTitle
        name="Распределение талонов по часам"
      />
      {ReportParams
        && (
          <div style={{ height: '300px' }}>
            <Line
              data={ReportParams}
              height={50}
              options={{
                maintainAspectRatio: false,
                intersect: true,
              }}
            />
          </div>
        )}
      {loader
        && (
          <PreloaderBox>
            <Preloader isOpen small />
          </PreloaderBox>
        )}
    </ReportBox>
  );
}

export default ReportLine;
