import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import moment from 'moment';
import { Title } from '../../../../common/ui/Common';
import request from '../../../../logic/utils/request';
import QualityTabletForm from './QualityTabletForm';
import QualityTabletTable from './Table/QualityTabletTable';

const { Panel } = Collapse;

function QualityTablet() {
  let isMaunted = true;
  const [tableData, setTableData] = useState([]);
  const [formParams, setFormParams] = useState([]);
  const [surveyList, setSurveyList] = useState([]);
  const [workplaceList, setWorkplaceList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [imageList, setImageList] = useState([]);

  const fetchTableData = async () => {
    const res = await request('/server/api/quality_control/');
    const { statusText, data: { controls } } = res;

    controls.forEach((el) => {
      if (el.config === null) el.configType = 'Общий';
      else el.configType = 'Пользовательский';
      el.created_on = moment(new Date(el.created_on)).format('DD.MM.YYYY HH:mm:ss');
      el.modified_on = moment(new Date(el.modified_on)).format('DD.MM.YYYY HH:mm:ss');
    });
    if (isMaunted && statusText === 'OK') setTableData(controls);
  };

  const fetchSurveyData = async () => {
    const res = await request('/server/public/surveys/');
    const { statusText, data: { data } } = res;
    if (isMaunted && statusText === 'OK') setSurveyList(data);
  };

  const fetchServerParams = async () => {
    const res = await request('/server/api/officeparams/');
    const { statusText, data: { data } } = res;
    if (isMaunted && statusText === 'OK') {
      let serverParams = {};
      data.forEach((element) => {
        if (element.param === 'qualityControlConfig') serverParams = JSON.parse(element.value);
      });
      setFormParams(serverParams);
    }
  };

  const fetchWorkPlace = async () => {
    const res = await request('/server/api/wplace/');
    const { statusText, data: { data } } = res;
    if (isMaunted && statusText === 'OK') {
      const wpList = [{ id: 0, name: 'Не выбрано' }];
      data.forEach((el) => {
        wpList.push({ id: el.id, name: el.name });
      });
      setWorkplaceList(wpList);
    }
  };

  const fetchVideo = async () => {
    const res = await Axios({
      method: 'get',
      url: '/api/videos',
    });
    const { statusText, data } = res;
    if (isMaunted && statusText === 'OK') {
      const videoData = [];
      data.forEach((el) => {
        videoData.push({ name: el.name, id: el.url });
      });
      setVideoList(videoData);
    }
  };

  const fetchImage = async () => {
    const res = await Axios({
      method: 'get',
      url: '/api/images',
    });
    const { statusText, data } = res;
    if (isMaunted && statusText === 'OK') {
      const videoData = [];
      if (Array.isArray(data)) {
        data.forEach((el) => {
          videoData.push({ name: el.name, id: el.url });
        });
      }
      setImageList(videoData);
    }
  };

  useEffect(() => {
    fetchTableData();
    fetchServerParams();
    fetchSurveyData();
    fetchWorkPlace();
    fetchVideo();
    fetchImage();
    return () => {
      isMaunted = false;
    };
  }, []);

  return (
    <>
      <Title>Планшеты оценки качества</Title>
      {formParams && (
      <Collapse
        style={{ marginBottom: '20px' }}
      >
        <Panel header="Показать общие настройки" key="1">
          <QualityTabletForm
            params={formParams}
            surveyList={surveyList}
            fetchTableData={fetchTableData}
            videoList={videoList}
            imageList={imageList}
          />
        </Panel>
      </Collapse>
      )}
      <QualityTabletTable
        tableData={tableData}
        fetchTableData={fetchTableData}
        surveyList={surveyList}
        videoList={videoList}
        workplaceList={workplaceList}
        formParams={formParams}
        imageList={imageList}
      />
    </>
  );
}

export default QualityTablet;
