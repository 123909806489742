import React from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import { ReactComponent as Edit } from '../../../../../common/images/edit.svg';
import c from '../../../../../common/ui/theme/Colors';
import iconFormatter from '../../../../../pages/private/utils/iconFormatter';
import sortCaret from '../../../../../pages/private/subpages/statistics/quality-control/utils/sortCaret';

function priceFormatter(column, colIndex, { filterElement, sortElement }, sort) {
  return (
    <div className={`filterBoxTable ${sort ? 'sort' : null}`}>
      { column.dataField !== 'Settings' && <Edit style={{ margin: '2px 10px 0 0' }} fill={c.main} /> }
      { filterElement }
      { sortElement }
    </div>
  );
}

const screenTypes = [
  { id: 'startScreen', name: 'Начальный экран' },
  { id: 'dictScreen', name: 'Экран одиночного выбора' },
  { id: 'inputScreen', name: 'Экран с полями ввода' },
  { id: 'btnScreen', name: 'Экран с кнопками' },
  { id: 'multiScreen', name: 'Экран множественного выбора' },
  { id: 'addressScreen', name: 'Экран ввода адреса' },
  { id: 'periodScreen', name: 'Экран ввода периода' },
  { id: 'infoScreen', name: 'Информационный экран' },
];

export default function TableConfig() {
  return {
    columns: [
      {
        dataField: 'headTitle',
        text: 'Наименование',
        sort: true,
        sortCaret,
        headerFormatter:
          (column, colIndex, { filterElement, sortElement }) => priceFormatter(
            column,
            colIndex,
            {
              filterElement,
              sortElement,
            },
            true,
          ),
        filter: textFilter({
          placeholder: 'Наименование',
        }),
        editable: false,
      },
      {
        dataField: 'screenSubType',
        text: 'Идентификатор экрана',
        sort: true,
        editable: true,
        // editor: {
        //  type: Type.TEXTAREA,
        // },
      },
      {
        dataField: 'title',
        text: 'Заголовок',
        sort: true,
        sortCaret,
        headerFormatter: (column, colIndex, { filterElement, sortElement }) => priceFormatter(
          column,
          colIndex,
          {
            filterElement,
            sortElement,
          },
          true,
        ),
        filter: textFilter({
          placeholder: 'Заголовок',
        }),
        editable: false,
      },
      {
        dataField: 'description',
        text: 'Описание',
        headerFormatter: priceFormatter,
        filter: textFilter({
          placeholder: 'Описание',
        }),
        editable: false,
        formatter(cell) {
          return cell;
        },
      },
      {
        dataField: 'type',
        text: 'Тип',
        headerFormatter: priceFormatter,
        filter: textFilter({
          placeholder: 'Тип',
        }),
        editable: false,
        formatter(cell) {
          return screenTypes.find((t) => t.id === cell) ? screenTypes.find((t) => t.id === cell).name : '';
        },
      },
      {
        dataField: 'Settings',
        text: ' ',
        isDummyField: true,
        headerFormatter: priceFormatter,
        formatter: () => iconFormatter({
          filter: false, write: true, params: false,
        }),
        formatExtraData: {},
        editable: false,
        headerStyle: {
          width: 100,
        },
      },
    ],
  };
}
