import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import request from '../../../../logic/utils/request';
import DevicesTable from './DevicesTable';
import NewDevice from './NewDevice/NewDevice';
import { Title, ContentBox, PreloaderBox } from '../../../../common/ui/Common';
import { useToggle } from '../../../../logic/hooks/useToggle';

function Devices({
  preloader, showError, write, history,
}) {
  const initialData = {
    ipaddr: '',
    port: 48180,
    type: '',
    name: '',
    zoneId: null,
    config: {
      alwaysOnTop: true,
      removeTickets: false,
    },
    configured: false,
    uuid: '0',
  };
  const [deviceList, setDeviceList] = useState([]);
  const [deviceListLoaded, setdeviceListLoaded] = useState(false);
  const [deviceData, setDeviceData] = useState(initialData);
  const [isOpen, toggleOpen] = useToggle(false);
  const [deviceUpdate, setDeviceUpdate] = useState(false);

  const handeDeviceUpdate = (newState) => {
    setDeviceUpdate(newState);
  };

  const fetchVersion = useCallback(async () => {
    const token = window.localStorage.getItem('token');
    const res = await axios.get(`/api/checkSatelliteVersion/?token=${token}`);
    setDeviceList([...res.data]);
  }, []);

  useEffect(() => {
    fetchVersion();
  }, [fetchVersion]);

  const fetchDevices = useCallback(async () => {
    preloader();
    try {
      const res = await request('/server/api/satellite/', 'get');
      const newDevices = [...res.data.data];
      res.data.data.forEach((item, i) => {
        const deviceItemVersion = { ...item };
        deviceItemVersion.status = 'Запрос версии...';
        newDevices[i] = deviceItemVersion;
      });
      setDeviceList([...newDevices]);
      setdeviceListLoaded(true);
      preloader();
    } catch (e) {
      preloader();
      showError(e.data.error);
    }
  }, [preloader, showError]);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  return (
    <>
      <Title>внешние устройства</Title>
      <ContentBox>
        <NewDevice
          initialData={initialData}
          deviceData={deviceData}
          setDeviceData={setDeviceData}
          fetchDevices={fetchDevices}
          toggleOpen={toggleOpen}
          isOpen={isOpen}
          deviceUpdate={deviceUpdate}
          setDeviceUpdate={handeDeviceUpdate}
          preloader={preloader}
          showError={showError}
          history={history}
          deviceList={deviceList}
        />
        <PreloaderBox>
          <DevicesTable
            write={write}
            deviceList={deviceList}
            deviceListLoaded={deviceListLoaded}
            setDeviceUpdate={handeDeviceUpdate}
            setDeviceData={setDeviceData}
            toggleOpen={toggleOpen}
            preloader={preloader}
            fetchDevices={fetchDevices}
            showError={showError}
          />
        </PreloaderBox>
      </ContentBox>
    </>
  );
}

export default Devices;
