import {
  Button, Tag, Popconfirm, Checkbox, Tooltip,
} from 'antd';
import moment from 'moment';
import React from 'react';
import Highlighter from 'react-highlight-words';
import SelectInput from '../../../../../pages/private/components/Input/SelectInput';

const TableConfig = (
  workplaceList,
  handleTableChange,
  handleOpenDrawer,
  resetToDefault,
  deleteRow,
  getColumnSearchProps,
) => ({
  columns: [
    {
      dataIndex: 'created_on',
      title: 'Дата добавления',
      width: 200,
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        const aToSec = new Date(moment(a.created_on, 'DD.MM.YYYY HH:mm:ss')).getTime();
        const bToSec = new Date(moment(b.created_on, 'DD.MM.YYYY HH:mm:ss')).getTime();
        return (aToSec - bToSec);
      },
      ...getColumnSearchProps('created_on', 'Дата добавления'),
    },
    {
      dataIndex: 'name',
      title: 'Название',
      ...getColumnSearchProps('name', 'Название'),
    },
    {
      dataIndex: 'hardware_id',
      title: 'hardware_id',
      width: 120,
      ...getColumnSearchProps('hardware_id', 'hardware_id'),
      render: (text, record, index, action) => {
        const content = action?.searchedText ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[action.searchedText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span>{text?.length > 20 ? `${text.substring(0, 20)}...` : text}</span>
        );

        return <Tooltip title={text}>{content}</Tooltip>;
      },
    },
    {
      dataIndex: 'modified_on',
      title: 'Дата обновления',
      width: 200,
      sorter: (a, b) => {
        const aToSec = new Date(moment(a.modified_on, 'DD.MM.YYYY HH:mm:ss')).getTime();
        const bToSec = new Date(moment(b.modified_on, 'DD.MM.YYYY HH:mm:ss')).getTime();
        return (aToSec - bToSec);
      },
      ...getColumnSearchProps('modified_on', 'Дата обновления'),
    },
    {
      dataIndex: '',
      title: 'Рабочее место',
      width: 250,
      render: (_, row) => (
        <SelectInput
          onChange={(e) => { handleTableChange(e, row.hardware_id); }}
          m="0 0 0 10px"
          noDefault
          value={row.wplace_id}
          options={workplaceList}
          name="wplace_id"
        />
      ),
    },
    {
      dataIndex: '',
      title: 'Тип конфига',
      width: 150,
      filters: [
        {
          text: 'Пользовательский',
          value: 'Пользовательский',
        },
        {
          text: 'Общий',
          value: 'Общий',
        },
      ],
      onFilter: (value, record) => record.configType.indexOf(value) === 0,
      render: (_, row) => {
        let tagColor = '';
        const tagText = row.configType;
        if (row.configType === 'Пользовательский') tagColor = 'geekblue';
        else tagColor = 'green';
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Tag
              color={tagColor}
              key={row.hardware_id}
              style={{
                height: ' 40px',
                margin: '0px',
                width: '100%',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              {tagText.toUpperCase()}
            </Tag>
          </div>
        );
      },
    },
    {
      dataIndex: '',
      title: 'Действия',
      width: 440,
      render: (_, row) => (
        <>
          <Button onClick={() => handleOpenDrawer(row)} size="large">Редактировать</Button>
          <Button onClick={() => resetToDefault(row)} disabled={!row.config} size="large" style={{ marginLeft: '8px' }}>Сбросить конфиг</Button>

          {window.localStorage.getItem('qualityTabletDeleteConfirmShow') !== 'true'
            ? (
              <Popconfirm
                title={(
                  <>
                    <div>Удалить?</div>
                    <Checkbox
                      size="small"
                      onChange={() => {
                        localStorage.setItem('qualityTabletDeleteConfirmShow', true);
                      }}
                      style={{ minWidth: '260px' }}
                    >
                      <span style={{ fontSize: '12px' }}>Больше не показывать это уведомление</span>
                    </Checkbox>
                  </>
            )}
                onConfirm={() => deleteRow(row)}
                okText="Да, удалить"
              >
                <Button size="large" style={{ marginLeft: '8px' }} danger>Удалить</Button>
              </Popconfirm>
            )
            : (
              <Button
                onClick={() => {
                  deleteRow(row);
                }}
                size="large"
                style={{ marginLeft: '8px' }}
                danger
              >
                Удалить
              </Button>
            )}
        </>
      ),
    },

  ],
});

export default TableConfig;
