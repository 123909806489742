import {
  Table, Drawer,
  Button, Input, Space,
} from 'antd';
import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import locale from 'antd/es/locale/ru_RU';
import request from '../../../../../logic/utils/request';
import QualityTabletForm from '../QualityTabletForm';
import TableConfig from './TableConfig';

function QualityTabletTable(props) {
  const {
    tableData, fetchTableData, surveyList, workplaceList, videoList, formParams, imageList,
  } = props;

  const [openDrawer, setOpenDtawen] = useState(false);
  const [DrawerData, setDarawenData] = useState();
  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const input = useRef(null);

  const handleTableChange = async (param, hardwareId) => {
    const { name, value } = param.target;
    await request(`/server/api/quality_control/${hardwareId}`, 'update', { [name]: value });
    toast.success('Выполнено');
    fetchTableData();
  };

  const handleOpenDrawer = (row) => {
    setOpenDtawen(true);
    setDarawenData(row);
  };

  const resetToDefault = async (row) => {
    if (row) await request(`/server/api/quality_control/${row.hardware_id}`, 'update', { config: null });
    fetchTableData();
  };

  const deleteRow = async (row) => {
    if (row) await request(`/server/api/quality_control/${row.hardware_id}`, 'delete');
    fetchTableData();
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, searchItemText) => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={input}
          placeholder={`Найти ${searchItemText}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Очистить
          </Button>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => input.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const columns = TableConfig(
    workplaceList,
    handleTableChange,
    handleOpenDrawer,
    resetToDefault,
    deleteRow,
    getColumnSearchProps,
  );

  console.log('columns:', columns);

  return (
    <>
      <Table
        rowKey="hardware_id"
        dataSource={tableData}
        columns={columns.columns}
        size="middle"
        bordered
        locale={locale.Table}
        pagination={{
          locale: locale.Pagination,
          //  pageSize: 10,
          //  pageSizeOptions: [10, 20, 50, 100],
          showSizeChanger: true,
        }}
      />

      <Drawer
        title={`Редактирование планшета ${DrawerData?.name}`}
        placement="right"
        onClose={() => { setOpenDtawen(false); }}
        visible={openDrawer}
        width={880}
      >
        <QualityTabletForm
          params={DrawerData?.config === null ? formParams : DrawerData?.config}
          surveyList={surveyList}
          fetchTableData={fetchTableData}
          record={DrawerData}
          videoList={videoList}
          imageList={imageList}
        />
      </Drawer>
    </>
  );
}

export default QualityTabletTable;
