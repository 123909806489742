import jsbarcode from 'jsbarcode';
import getText from './getText';
import Hyphenation from './hyphenation';

const getElement = async ({
  ctx, style, data, setFinishDraw, ref, number,
}) => {
  console.log(data);

  const canvas = document.createElement('canvas');
  const promiseAll = [];
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const el in style) {
    const element = style[el];

    console.log('element === ', element);
    const center = element.align || element.align === 'center';
    if (element.url) {
      if (element.show) {
        if (element.name === 'Штрихкод') {
          jsbarcode(canvas, String(data.ticket.id), {
            width: 2, height: 150, displayValue: false, margin: 0,
          });
          promiseAll.push(new Promise(((resolve) => {
            const imageBarcode = new Image();
            imageBarcode.onload = () => {
              resolve({ image: imageBarcode, name: 'barcode', element });
            };
            imageBarcode.src = canvas.toDataURL('image/png');
          })));
        } else {
          promiseAll.push(new Promise(((resolve) => {
            const image = new Image();
            image.onload = () => resolve({ image, name: 'image', element });
            image.src = element.url;
          })));
        }
      }
    } else {
      ctx.fillStyle = '#000';
      ctx.textAlign = center ? 'center' : 'left';
      ctx.font = `bold ${element.fontSize}px ${element.fontFamily}`;
      const text = getText(element.textInput, data, number);
      const maxWidth = 450;
      const lineHeight = 1.2;
      if (center) {
        if (element.textInput) {
          Hyphenation(ctx, text, 250, element.y, maxWidth, lineHeight, element.fontSize);
        }
      } else if (element.textInput) {
        Hyphenation(ctx, text, element.x, element.y, maxWidth, lineHeight, element.fontSize);
      }
    }
  }
  await Promise.all(promiseAll).then((value) => {
    let count = 0;
    value.forEach((prom) => {
      count += 1;
      const { image, name, element } = prom;
      console.log(element);

      const center = element.align;
      if (name === 'barcode') {
        if (center) {
          // eslint-disable-next-line no-unsafe-optional-chaining
          const canvasWidth = ref?.current?.width ?? 0;
          ctx.drawImage(image, (canvasWidth / 2) - 113, element.y, 225, 90);
        } else {
          ctx.drawImage(image, element.x, element.y, 225, 90);
        }
      } else {
        const diff = element.height / image.height;
        const imageWidth = image.width * diff;
        if (center) {
          ctx.drawImage(
            image,
            (ref?.current?.width ?? 0) / 2 - imageWidth / 2,
            element.y,
            imageWidth,
            element.height,
          );
        } else {
          ctx.drawImage(image, element.x, element.y, image.width * diff, element.height);
        }
      }
      if (count === value.length) setFinishDraw(true);
    });
  });
};

export default getElement;
