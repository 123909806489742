import React, { useState } from 'react';
import { Upload, message, Progress } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

// const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB в байтах

function VideoUpload({ save, showError }) {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const uploadProps = {
    name: 'video',
    multiple: false,
    accept: 'video/mp4',
    showUploadList: false,
    beforeUpload: (file) => {
      // Проверка на кириллицу в имени файла
      const hasCyrillic = /[а-яА-ЯёЁ]/.test(file.name);
      if (hasCyrillic) {
        showError('Имя файла не должно содержать кириллицу');
        return false;
      }

      // Проверка расширения
      const isMP4 = file.type === 'video/mp4';
      if (!isMP4) {
        showError('Поддерживаются только MP4 файлы');
        return false;
      }

      // Создаем FormData и отправляем
      const formData = new FormData();
      formData.append('video', file);
      formData.append('name', file.name); // Используем имя файла как название

      setIsUploading(true);
      setUploadProgress(0);

      // Создаем XMLHttpRequest для отслеживания прогресса
      const xhr = new XMLHttpRequest();

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded * 100) / event.total);
          setUploadProgress(progress);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          try {
            const response = JSON.parse(xhr.response);
            if (response.success) {
              setIsUploading(false);
              message.success(`Видео ${file.name} успешно загружено`);
              save(response);
            } else {
              setIsUploading(false);
              showError(response.error || 'Ошибка при загрузке видео');
            }
          } catch (e) {
            setIsUploading(false);
            showError('Ошибка при обработке ответа сервера');
          }
        } else {
          setIsUploading(false);
          showError(`Ошибка при загрузке видео: ${xhr.status}`);
        }
      };

      xhr.onerror = () => {
        setIsUploading(false);
        showError('Ошибка при загрузке файла');
      };

      xhr.open('POST', '/api/videos', true);
      xhr.send(formData);

      return false; // Предотвращаем автоматическую загрузку Upload компонента
    },
    onChange: (info) => {
      const { status } = info.file;
      if (status === 'removed') {
        setIsUploading(false);
        setUploadProgress(0);
      }
    },
  };

  return (
    <div>
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Нажмите или перетащите видео файл в эту область
        </p>
        <p className="ant-upload-hint">
          Поддерживаются только MP4 файлы
        </p>
        <p className="ant-upload-hint">
          Имя файла не должно содержать кириллицу
        </p>
        {isUploading && (
          <p style={{ marginTop: 8, color: '#1890ff' }}>
            Загрузка видео:
            {' '}
            {uploadProgress}
            %
          </p>
        )}
      </Dragger>
      {
        isUploading && (
          <div style={{ marginTop: 16 }}>
            <Progress percent={uploadProgress} status="active" />
          </div>
        )
      }
    </div>
  );
}

export default VideoUpload;
