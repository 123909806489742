import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Title } from '../../../../../../common/ui/Common';
import { ReportBox } from '../../../../components/report/ReportTableColumns';

function Graphic(props) {
  const { data: graphicData, officeParams } = props;
  // Получаем время начала и окончания работы из параметров
  const startHour = parseInt(officeParams.find((param) => (param.param === 'officeStartTime')).value, 10);

  // Получаем полное значение времени окончания (может содержать минуты)
  const closingTimeValue = officeParams.find((param) => (param.param === 'officeClosingTime')).value;

  // Проверяем, содержит ли значение двоеточие (формат HH:MM)
  let endHour;
  if (closingTimeValue.includes(':')) {
    const [hours, minutes] = closingTimeValue.split(':').map((part) => parseInt(part, 10));
    // Если есть минуты, округляем в большую сторону
    endHour = minutes > 0 ? hours + 1 : hours;
  } else {
    // Если формат только часы
    endHour = parseInt(closingTimeValue, 10);
  }

  // Преобразуем данные в массив с индексами по часам
  const hourlyData = new Array(24).fill(0);

  // Заполняем массив данными из graphicData
  // Компенсируем смещение на 1 час вперед в данных
  graphicData.forEach((item) => {
    // Час в данных должен быть числом от 0 до 23
    const hour = parseInt(item.hour, 10);
    // Компенсируем смещение на 1 час вперед
    const correctedHour = hour > 0 ? hour - 1 : 23; // Если час 0, то это должен быть 23 час предыдущего дня
    if (correctedHour >= 0 && correctedHour < 24) {
      hourlyData[correctedHour] = item.closed;
    }
  });

  // Фильтруем данные только для рабочих часов
  // Важно: данные с индексом i соответствуют часу i:00-i+1:00
  const filteredClosed = hourlyData.slice(startHour, endHour);

  const getLabels = () => {
    const labels = [];
    for (let i = startHour; i < endHour; i++) {
      if (i === 0) {
        labels.push('00:00-01:00');
      } else {
        labels.push(`${String(i).padStart(2, '0')}:00-${String(i + 1).padStart(2, '0')}:00`);
      }
    }
    return labels;
  };

  const graph = {
    labels: getLabels(),
    datasets: [{
      label: 'Количество обслуженых талонов',
      fill: false,
      lineTension: 0.05,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: '#20a8d8',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#20a8d8',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#20a8d8',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: filteredClosed,
    }],
  };

  return (
    <ReportBox>
      <Title>Почасовое распределение обслуженных талонов за текущий день</Title>
      <div style={{ height: '300px', marginBottom: 100 }}>
        <Bar
          data={graph}
          height={100}
          options={{
            maintainAspectRatio: false,
          }}
        />
      </div>
    </ReportBox>
  );
}

export default React.memo(Graphic);
