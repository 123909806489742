import Request from '../../../../../../../../logic/utils/request';

export default async function SqlQuery(sessionDataId) {
  const query = `select q.id, 
  e.sessiondata_id,
  w.question_id,
  r.questiontext,
  w.answerint,
  w.answertxt,
  w.weight,
  w."type",
  r."position"
  from eq.tqcsessionquestionanswer q,
  eq.tqcquestioncorrectanswer w,
  eq.tqcquestion r,
  eq.tqcsessionquestion e
  where w.id = q.questioncorrectanswer_id
  and w.question_id = r.id 
  and e.id = q.sessionquestion_id
  and e.sessiondata_id = '${sessionDataId}'`;

  const dataObjects = [];
  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const getColumnsNames = (answer) => {
    const data = [];
    const { columnNames, data: answerData } = answer;
    answerData.forEach((dv) => {
      const rElem = {};
      dv.forEach((v, idx) => {
        rElem[columnNames[idx]] = v;
      });
      data.push(rElem);
    });

    return data;
  };

  let answer = getColumnsNames(res.data);

  // Сортируем по position
  answer = answer.sort((a, b) => Number(a.position) - Number(b.position));

  // Расчет общей средней оценки для всех вопросов
  const validWeights = answer.filter((item) => item.weight !== null && item.weight !== undefined);
  const totalWeight = validWeights.reduce((sum, item) => sum + Number(item.weight), 0);
  const avgMark = validWeights.length > 0 ? Number((totalWeight / validWeights.length).toFixed(2)) : 0;

  // Добавляем отсортированные данные с общей средней оценкой
  answer.forEach((v) => {
    dataObjects.push({
      ...v,
    });
  });

  return { dataObjects, query, avgMark };
}
