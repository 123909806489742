import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import Request from '../request';
import Preload from '../../../preload';
import Canvas from './canvas';
import findError from '../findError';
import store from '../../../../store/store';
import { resetTicket, setPre } from '../../../../store/actions';
import { PrintBox } from '../../../utils/style';
import { resetScanPolicyStore } from '../../../../../../../logic/store/pullstate/scanPolicy';
import CanvasPre from './canvasPre';

const { dispatch } = store;

function Print(props) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const {
    ticket, config, preEntry, style, startScreen,
  } = useSelector(
    (state) => state,
  );
  const [needPrint, setNeedPrint] = useState(true);
  const [preEntryAllert, setPreEntryAllert] = useState(null);
  //  const [officeParams, setOfficeParams] = useState({});
  const navigate = useNavigate();
  const { errorUrl } = config;

  const claimTicket = async (tk) => Axios.patch(
    '/medsoft',
    { tk },
    { timeout: 15000 },
  );

  const createTicket = async (tk) => Request('/server/public/createTicket', 'no', { data: tk });

  const sendTicket = async () => {
    const tk = {
      ...ticket,
      dictionary: ticket.dictionary.map(
        ({ to, inout, ...otherProps }) => otherProps,
      ),
      zone_id: config.idZone,
    };
    const emuPin = localStorage.getItem('emulatePreRecord');
    if (emuPin) {
      tk.comment = `Пинкод записи: ${emuPin}`;
      tk.numPriority = 1000;
    }
    try {
      const res = tk?.appointmentId
        ? (await claimTicket(tk)).data
        : await createTicket(tk);
      const error = findError({
        res,
        navigate,
        dispatch,
        errorUrl,
      });
      if (!error) {
        setData(res.data);
      } else {
        dispatch(resetTicket());
      }
    } finally {
      setIsLoading(false);
    }
  };

  const sendPin = async () => {
    // const arrDictionary = [{fv: [], fid: id}]

    ticket.satellite_id = +ticket.satellite_id;
    try {
      const res = await Request(
        '/server/public/preorder/createTicketByPin',
        'no',
        { data: ticket },
      );
      const error = findError({
        res,
        navigate,
        dispatch,
        errorUrl,
      });
      if (!error) {
        setData(res.data);
        setIsLoading(false);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (config.twoScreen) navigate(startScreen); // magick
    if (preEntry.pin) {
      setData(preEntry);
      setIsLoading(false);
    } else if (ticket.pinCode) {
      sendPin();
    } else {
      sendTicket();
    }
    return () => {
      dispatch(setPre({}));
      resetScanPolicyStore();
    };
  }, []);

  const getServerParams = async () => {
    try {
      const newParams = await Request(
        '/server/public/getTicketPrintMode/',
        'get',
        {},
      );
      const {
        data: { are_tickets_printed: areTicketsPrinted, pa_allowed_time_before: paAllowedTimeBefore },
      } = newParams;
      // TODO исправить метод, проверить получение параметров

      setPreEntryAllert(paAllowedTimeBefore);
      setNeedPrint(areTicketsPrinted !== 0);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('e === ', e);
    }
  };

  // Загружаем параметры сразу после получения данных
  useEffect(() => {
    if (!isLoading) {
      getServerParams();
    }
  }, [isLoading]);

  function getCanvas() {
    if (isLoading || preEntryAllert === null) {
      return (<Preload print />);
    }

    if (!data.ticket) {
      return (
        <CanvasPre
          dispatch={dispatch}
          errorUrl={errorUrl}
          {...props}
          config={config}
          data={data}
          needPrint={needPrint}
          style={props?.style}
          startScreen={startScreen}
          preEntryAllert={preEntryAllert}
        //  officeParams={officeParams}
        />
      );
    }

    return (
      <Canvas
        dispatch={dispatch}
        errorUrl={errorUrl}
        {...props}
        config={config}
        data={data}
        needPrint={needPrint}
      />
    );
  }

  return (
    <PrintBox style={{ background: style.body.background }}>
      {getCanvas()}
    </PrintBox>
  );
}

function compareProps(prevProps, nextProps) {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}

export default React.memo(Print, compareProps);
