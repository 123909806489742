import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Row, Col, Card } from 'antd';
import VideoUpload from './VideoUpload';
import List from './list';

function BoardVideos({ showError, preloader }) {
  // const [title, setTitle] = useState(undefined);
  const [videos, setVideos] = useState([]);

  const getVideos = useCallback(async () => {
    preloader();
    try {
      axios({
        method: 'get',
        url: '/api/videos',
      })
        .then((res) => setVideos(res.data));
      preloader();
    } catch (e) {
      preloader();
      showError(e.data.error);
    }
  }, [showError, preloader]);

  useEffect(() => {
    getVideos();
  }, [getVideos]);

  const uploadVideo = async () => {
    // Видео уже загружено, просто обновляем список
    getVideos();
  };

  const deleteVideo = async (id) => {
    preloader();
    try {
      await axios({
        method: 'delete',
        url: '/api/videos',
        data: {
          id,
        },
      });
      preloader();
      getVideos();
      toast.success('Удалено');
    } catch (e) {
      preloader();
      showError(e.data.text);
    }
  };
  const setShow = async (id) => {
    preloader();
    try {
      await axios({
        method: 'patch',
        url: '/api/videos',
        data: {
          id,
        },
      });
      preloader();
      getVideos();
      toast.success('Выполнено');
    } catch (e) {
      preloader();
      showError(e.data.text);
    }
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={8}>
        <Card bordered={false}>
          <VideoUpload
            save={uploadVideo}
            showError={showError}
          />
        </Card>
      </Col>
      <Col span={16}>
        <List deleteVideo={deleteVideo} setShow={setShow} videos={videos} />
      </Col>
    </Row>
  );
}

export default BoardVideos;
