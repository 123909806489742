import Request from '../../../../../../../../../logic/utils/request';

export default async function SqlQuery(ticketId) {
  const query = `select * from 
  (select ht.ticket_id,
    to_char(ht.modified_on, 'DD.MM.YYYY HH24:MI:SS') as close_data,
    to_char(ht.created_on, 'DD.MM.YYYY HH24:MI:SS') as create_data, 
    ht.number as ticket_number,
    qt.fullname as queue_name, 
    ut.username as operator_name, 
    ut.fio as operator_fio,
    zt."name" as zone_name, 
    ht.prior, 
    ht.totalwait * interval '1 sec' as wait_time,
    ht.totaldecisionwait * interval '1 sec' as decision_time, 
    ht.totalproc * interval '1 sec' as proc_time,
    ht.wp_id, coalesce (ht.wp_id, 0) as new_wp_id, 
    ht.status, 
    ht.id as row_id,
    ht.procforsecs * interval '1 sec' as current_wait_time,
    ht."comment" as ticket_comment,
    ht.servedquestion_id
    from eq.ttickethistory ht, 
    eq.tqueue qt, 
    eq.tuser ut, 
    eq.tzone zt
    where 
    ht.modified_by_id = ut.id 
    and ht.q_id = qt.id 
    and ht.zone_id = zt.id
  and ht.ticket_id = '${ticketId}'
) mt 
left join (select id, "name" from eq.twplace) wp on mt.new_wp_id = wp.id 
left join (select id, "name" as questionText from eq.tservedquestion t) sq on mt.servedquestion_id = sq.id
order by mt.close_data::timestamp`;

  const myData = [];
  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const { data: answer, columnNames } = res.data;

  answer.forEach((dv) => {
    const rElem = {};
    dv.forEach((v, idx) => {
      rElem[columnNames[idx]] = v;
    });
    myData.push(rElem);
  });

  myData.sort((a, b) => a.row_id - b.row_id);

  return { myData, query };
}
