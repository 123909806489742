import React from 'react';
import {
  Typography, Button, Row, Col, Space, Tooltip, Card, Switch,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

// const { Title } = Typography;

function List({ videos = [], deleteVideo, setShow }) {
  return (
    <div>
      {/* <Title level={4} style={{ marginBottom: '24px' }}>Список загруженных видео</Title> */}
      <Row gutter={[24, 24]}>
        {videos.map((el) => (
          <Col key={el.id} xs={24} sm={24} md={12} lg={12}>
            <Card
              size="small"
              title={(
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                }}
                >
                  <Tooltip title={el.name} placement="topLeft">
                    <Typography.Text
                      style={{
                        fontSize: '18px',
                        flex: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        cursor: 'default',
                      }}
                    >
                      {el.name}
                    </Typography.Text>
                  </Tooltip>
                  <Space size="middle">
                    <Space align="center" size="small">
                      <Typography.Text style={{ fontSize: '16px' }}>Показывать видео</Typography.Text>
                      <Switch
                        checked={el.show}
                        onChange={() => setShow(el.id)}
                        checkedChildren="да"
                        unCheckedChildren="нет"
                      />
                    </Space>
                    <Tooltip title="Удалить видео">
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => deleteVideo(el.id)}
                      />
                    </Tooltip>
                  </Space>
                </div>
              )}
              headStyle={{
                padding: '10px 10px',
              }}
              extra={
                null
              }
              bodyStyle={{ padding: 0 }}
            >
              <video
                src={el.url}
                style={{
                  width: '100%',
                  display: 'block',
                  backgroundColor: '#f0f0f0',
                }}
                controls
                loop
              >
                <track default kind="captions" />
              </video>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default List;
